export const contentRouter = {
                        path: 'content',
                        name: 'main-content',
                        component: () => import( '@/views/content/index.content.view.vue'),
                        meta: {
                            title:'Manage Contents'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-content-list',
                                component: () => import( '@/views/content/list.content.view.vue')
                            },{
                                path: 'create',
                                name: 'main-content-create',
                                component: () => import( '@/views/content/create.content.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-content-edit',
                                component: () => import( '@/views/content/edit.content.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-content-preview',
                                component: () => import( '@/views/content/preview.content.view.vue')
                            },
                        ]
                    }