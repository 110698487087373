export const includeRouter = {
                        path: 'include',
                        name: 'main-include',
                        component: () => import( '@/views/include/index.include.view.vue'),
                        meta: {
                            title:'Manage Includes'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-include-list',
                                component: () => import( '@/views/include/list.include.view.vue')
                            },{
                                path: 'create',
                                name: 'main-include-create',
                                component: () => import( '@/views/include/create.include.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-include-edit',
                                component: () => import( '@/views/include/edit.include.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-include-preview',
                                component: () => import( '@/views/include/preview.include.view.vue')
                            },
                        ]
                    }