
import {defineComponent, reactive, ref} from 'vue';
import {useMainStore} from "@/store/main";

 function allowedMenu(menu:Array<any> = [], path:any ){
   let allowedPath = [] as Array<any>
   let allow = false
   //allowedPath.push('/main' )
   // allowedPath.push('/main/404' )
   allowedPath.push('/main/profile' )

   menu.forEach((sg : any)=>{
        if(sg.sys_menu_group){
           sg.sys_menu_group.forEach((s : any)=>{
               // console.log(to.path,s.slug)
                allowedPath.push('/main/'+s.slug )
           })
        }
      })

    allowedPath.forEach(p=>{
     // console.log( ['/main/404', '/main'].includes(path),p,path)
      if(path.includes(p) || ['/main/404', '/main','/main/profile'].includes(path)){
        allow =  true
      }
    })

    return allow;
  }

export default defineComponent({
  name: 'HomeView',
  components: {},
  setup() {},
  data(){
     return {

     }
  },

  async beforeRouteEnter(to, from, next) {

    const mainStore = useMainStore()
   // await console.log(mainStore.getSytemMenu)
    await mainStore.actionCheckLoggedIn(mainStore.getContext);


    if (mainStore.getIsLoggedIn) {

   //console.log(!allowedMenu(mainStore.getUserProfile.menu,to.path as any))
    if(!allowedMenu(mainStore.getUserProfile.menu,to.path as any) && to.path!=='/main/404'){
     // console.log(!allowedMenu(mainStore.getUserProfile.menu,to.path as any) )
      next('/main/404');
    }
     // console.log(mainStore.getUserProfile.menu)
      if (to.path === '/login' || to.path === '/') {
        next('/main');
      } else {
        next();
      }
    } else {
      if (to.path === '/' || (to.path as string).startsWith('/main') || (to.path as string).startsWith('/filemanager')) {
        next('/login');
      } else {
        next();
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const mainStore = useMainStore()
    //await console.log(mainStore.getSytemMenu)
   /* const allowedPath = ref([] as any)
    let access = ref(false)  ;*/


    //console.log(allowedPath.value)
    await mainStore.actionCheckLoggedIn(mainStore.getContext);

    if (mainStore.getIsLoggedIn) {
     if(!allowedMenu(mainStore.getUserProfile.menu,to.path as any) && to.path!=='/main/404'){
         // console.log(!allowedMenu(mainStore.getUserProfile.menu,to.path as any) )
          next('/main/404');
        }
     /* if(!allowedPath.value.includes(to.path)){
        next('/main/404');
      }*/

      if (to.path === '/login' || to.path === '/') {
        next('/main');
      } else {
        //if(to.name === 'main-users')
        next();
      }
    } else {
      if (to.path === '/' || (to.path as string).startsWith('/main') || (to.path as string).startsWith('/filemanager')) {
        next('/login');
      } else {
        next();
      }
    }
  }
});
