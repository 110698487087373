export const clientsRouter = {
                        path: 'clients',
                        name: 'main-clients',
                        component: () => import( '@/views/clients/index.clients.view.vue'),
                        meta: {
                            title:'Manage Clients'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-clients-list',
                                component: () => import( '@/views/clients/list.clients.view.vue')
                            },{
                                path: 'create',
                                name: 'main-clients-create',
                                component: () => import( '@/views/clients/create.clients.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-clients-edit',
                                component: () => import( '@/views/clients/edit.clients.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-clients-preview',
                                component: () => import( '@/views/clients/preview.clients.view.vue')
                            },
                        ]
                    }