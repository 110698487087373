<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
    >

      <v-card>
        <v-card-title class="text-h5">
          {{title}}
        </v-card-title>
        <v-card-text>{{description}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
              <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Отмена
          </v-btn>
          <slot>

          </slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "DialogComponent",
  props:['title','description','show'],
  watch:{
    show(new_, old_){
      this.dialog = new_
    }
  },
  data () {
      return {
        dialog: false,
      }}

}
</script>

<style scoped>

</style>