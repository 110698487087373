export const lessonsRouter = {
                        path: 'lessons',
                        name: 'main-lessons',
                        component: () => import( '@/views/lessons/index.lessons.view.vue'),
                        meta: {
                            title:'Manage Lessonss'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-lessons-list',
                                component: () => import( '@/views/lessons/list.lessons.view.vue')
                            },{
                                path: 'create',
                                name: 'main-lessons-create',
                                component: () => import( '@/views/lessons/create.lessons.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-lessons-edit',
                                component: () => import( '@/views/lessons/edit.lessons.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-lessons-preview',
                                component: () => import( '@/views/lessons/preview.lessons.view.vue')
                            },
                        ]
                    }