export const partnersRouter = {
                        path: 'partners',
                        name: 'main-partners',
                        component: () => import( '@/views/partners/index.partners.view.vue'),
                        meta: {
                            title:'Manage Partnerss'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-partners-list',
                                component: () => import( '@/views/partners/list.partners.view.vue')
                            },{
                                path: 'create',
                                name: 'main-partners-create',
                                component: () => import( '@/views/partners/create.partners.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-partners-edit',
                                component: () => import( '@/views/partners/edit.partners.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-partners-preview',
                                component: () => import( '@/views/partners/preview.partners.view.vue')
                            },
                        ]
                    }