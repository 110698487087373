export const servicesRouter = {
                        path: 'services',
                        name: 'main-services',
                        component: () => import( '@/views/services/index.services.view.vue'),
                        meta: {
                            title:'Manage Servicess'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-services-list',
                                component: () => import( '@/views/services/list.services.view.vue')
                            },{
                                path: 'create',
                                name: 'main-services-create',
                                component: () => import( '@/views/services/create.services.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-services-edit',
                                component: () => import( '@/views/services/edit.services.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-services-preview',
                                component: () => import( '@/views/services/preview.services.view.vue')
                            },
                        ]
                    }