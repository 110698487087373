export const diagnosisRouter = {
                        path: 'diagnosis',
                        name: 'main-diagnosis',
                        component: () => import( '@/views/diagnosis/index.diagnosis.view.vue'),
                        meta: {
                            title:'Manage Diagnosiss'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-diagnosis-list',
                                component: () => import( '@/views/diagnosis/list.diagnosis.view.vue')
                            },{
                                path: 'create',
                                name: 'main-diagnosis-create',
                                component: () => import( '@/views/diagnosis/create.diagnosis.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-diagnosis-edit',
                                component: () => import( '@/views/diagnosis/edit.diagnosis.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-diagnosis-preview',
                                component: () => import( '@/views/diagnosis/preview.diagnosis.view.vue')
                            },
                        ]
                    }