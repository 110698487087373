import {createApp} from 'vue'

import App from './App.vue'
import router from './router'
import {createPinia} from "pinia";
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import NotificationsManager from '@/components/NotificationsManager.vue';
import DialogComponent from '@/components/DialogComponent.vue';
import './registerServiceWorker';

import VueSocketIO from 'vue-socket.io'



loadFonts()

createApp(App)
    .use(router)
    .use(vuetify)

    .use(createPinia())
    .component('NotificationsManager', NotificationsManager)
    .component('DialogComponent', DialogComponent)
    .mount('#app')