export const organizationsRouter = {
                        path: 'organizations',
                        name: 'main-organizations',
                        component: () => import( '@/views/organizations/index.organizations.view.vue'),
                        meta: {
                            title:'Organizations'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-organizations-list',
                                component: () => import( '@/views/organizations/list.organizations.view.vue')
                            },{
                                path: 'create',
                                name: 'main-organizations-create',
                                component: () => import( '@/views/organizations/create.organizations.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-organizations-edit',
                                component: () => import( '@/views/organizations/edit.organizations.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-organizations-preview',
                                component: () => import( '@/views/organizations/preview.organizations.view.vue')
                            },
                        ]
                    }