export const structureRouter = {
                        path: 'structure',
                        name: 'main-structure',
                        component: () => import( '@/views/_public/structure/index.structure.view.vue'),
                        meta: {
                            title:'Manage Structures'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-structure-list',
                                component: () => import( '@/views/_public/structure/list.structure.view.vue')
                            },{
                                path: 'create',
                                name: 'main-structure-create',
                                component: () => import( '@/views/_public/structure/create.structure.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-structure-edit',
                                component: () => import( '@/views/_public/structure/edit.structure.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-structure-preview',
                                component: () => import( '@/views/_public/structure/preview.structure.view.vue')
                            },
                        ]
                    }