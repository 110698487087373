
import { defineComponent, onMounted, ref } from 'vue'

// import {useMainStore} from "@/store/main";
// const mainStore = useMainStore()


export default defineComponent({
  name: 'App',
  components: {},


  setup() {

    onMounted(() => {
      //items.value = mainStore.items;
    });

    return {

    }
    ;},
  data () {
    return {
      //
    }
  },
})
