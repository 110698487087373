export const roleRouter = {
                        path: 'roles',
                        name: 'main-roles',
                        component: () => import( '@/views/roles/index.roles.view.vue'),
                        meta: {
                            title:'Manage Roles'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-roles-list',
                                component: () => import( '@/views/roles/list.roles.view.vue')
                            },{
                                path: 'create',
                                name: 'main-roles-create',
                                component: () => import( '@/views/roles/create.roles.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-roles-edit',
                                component: () => import( '@/views/roles/edit.roles.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-roles-preview',
                                component: () => import( '@/views/roles/preview.roles.view.vue')
                            },
                        ]
                    }