export const blogCategoryRouter = {
                        path: 'blog-category',
                        name: 'main-blog-category',
                        component: () => import( '@/views/blog-category/index.blog-category.view.vue'),
                        meta: {
                            title:'Manage BlogCategorys'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-blog-category-list',
                                component: () => import( '@/views/blog-category/list.blog-category.view.vue')
                            },{
                                path: 'create',
                                name: 'main-blog-category-create',
                                component: () => import( '@/views/blog-category/create.blog-category.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-blog-category-edit',
                                component: () => import( '@/views/blog-category/edit.blog-category.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-blog-category-preview',
                                component: () => import( '@/views/blog-category/preview.blog-category.view.vue')
                            },
                        ]
                    }