import axios from 'axios';
import { apiUrl } from '@/env';
import {IUserProfile, IUserProfileUpdate, IUserProfileCreate, IUserOrganization} from '@/interfaces/user.model';
import {ISystemMenu} from "@/interfaces/system.model";

export function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async getSystemMenuInterface(token: string) {
    return axios.get<ISystemMenu>(`${apiUrl}/api/v1/system-menu/interface`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async getUsersOrganization(token: string) {
    return axios.get<IUserOrganization[]>(`${apiUrl}/api/v1/users/organization`, authHeaders(token));
  },
  async getUser(token: string, userID:number) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/${userID}`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async searchInBase(token: string, data = {}) {
    return axios.post(`${apiUrl}/api/v1/general/search-in-base/`, data, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
};
