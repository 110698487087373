export const menuRouter = {
                        path: 'menu',
                        name: 'main-menu',
                        component: () => import( '@/views/menu/index.menu.view.vue'),
                        meta: {
                            title:'Manage Menus'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-menu-list',
                                component: () => import( '@/views/menu/list.menu.view.vue')
                            },{
                                path: 'create',
                                name: 'main-menu-create',
                                component: () => import( '@/views/menu/create.menu.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-menu-edit',
                                component: () => import( '@/views/menu/edit.menu.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-menu-preview',
                                component: () => import( '@/views/menu/preview.menu.view.vue')
                            },
                        ]
                    }