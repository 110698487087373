import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/IndexView.vue'
import {roleRouter} from "@/router/roles.router";
import {systemMenuRouter} from "@/router/system-menus.router";
import {organizationsRouter} from "@/router/organizations.router";
import {structureRouter} from "@/router/structure.router";
import {blogsRouter} from "@/router/blogs.router";
import {blogCategoryRouter} from "@/router/blog-category.router";
import {contentRouter} from "@/router/content.router";
import {menuRouter} from "@/router/menu.router";
import {includeRouter} from "@/router/include.router";
import {lessonsRouter} from "@/router/lessons.router";
import {userPositionRouter} from "@/router/user-position.router";
import {clientsRouter} from "@/router/clients.router";
import {diagnosisRouter} from "@/router/diagnosis.router";
import {inventoryRouter} from "@/router/inventory.router";
import {servicesRouter} from "@/router/services.router";
import {reviewsRouter} from "@/router/reviews.router";
import {partnersRouter} from "@/router/partners.router";
import {eventsRouter} from "@/router/events.router";


const prefix = 'Demey CRM:' as string


const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        children: [
            {
                path: '/login',
                name: 'login',
                component: () => import( '@/views/Login.vue'),
            },
            {
                path: '/recover-password',
                name: 'recover-password',
                component: () => import( '@/views/PasswordRecoverView.vue'),
            },
            {
                path: '/main',
                name: 'main',
                component: () => import( '@/views/main/index.View.vue'),
                meta: {
                    title:'Главная'
                },

                children: [
                    {
                      path: '',
                      name: 'dashboard',
                      component: () => import( '@/views/main/dashboard.View.vue'),
                        meta: {
                    title:'Показатели'
                    },
                    },
                    {
                        path: 'profile',
                        name: 'profile',
                        component: () => import( '@/views/main/profile/profile.View.vue'),
                        children:[
                          {
                            path: 'settings',
                            name: 'settings',
                            component: () => import( '@/views/main/profile/settings.View.vue'),
                          }
                        ]
                    },
                    {
                        path: 'users',
                        name: 'main-users',
                        component: () => import( '@/views/users/index.View.vue'),
                        meta: {
                            title:'Пользователи'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-users-list',
                                component: () => import( '@/views/users/list.users.View.vue')
                            },{
                                path: 'create',
                                name: 'main-users-create',
                                component: () => import( '@/views/users/create.users.View.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-users-edit',
                                component: () => import( '@/views/users/edit.users.View.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-users-preview',
                                component: () => import( '@/views/users/preview.users.View.vue')
                            },
                        ]
                    },
                    {
                      path: 'timetable',
                      name: 'Расписание',
                      component: () => import( '@/views/_public/timetable.view.vue'),
                        meta: {
                             title:'Расписание'
                        },
                    },
                    roleRouter,
                    systemMenuRouter,
                    structureRouter,
                    blogsRouter,
                    blogCategoryRouter,
                    contentRouter,
                    menuRouter,
                    includeRouter,
                    lessonsRouter,
                    userPositionRouter,
                    clientsRouter,
                    diagnosisRouter,
                    inventoryRouter,
                    servicesRouter,
                    reviewsRouter,
                    partnersRouter,
                    eventsRouter,
                    organizationsRouter,

                    {
                        path: 'company',
                        name: 'public-company',
                        component: () => import( '@/views/_public/company/index.View.vue'),
                        children:[
                          {
                            path: '',
                            name: 'public-company-list',
                            component: () => import( '@/views/_public/company/list.company.view.vue'),
                          },{
                            path: 'edit/:id',
                            name: 'public-company-edit',
                            component: () => import( '@/views/_public/company/edit.company.view.vue'),
                          }
                        ]
                    },
                    {
                        path: 'reports',
                        name: 'reports',
                        component: () => import( '@/views/reports/index.View.vue'),
                        children:[
                          {
                            path: 'wages',
                            name: 'reports-wages',
                            component: () => import( '@/views/reports/wages.reports.view.vue'),
                          },
                            {
                            path: 'lessons',
                            name: 'reports-lessons',
                            component: () => import( '@/views/reports/lessons.reports.view.vue'),
                          },
                            {
                            path: 'clients',
                            name: 'reports-clients',
                            component: () => import( '@/views/reports/clients.reports.view.vue'),
                          }
                        ]
                    },
                    {
                        path: 'journals',
                        name: 'journals',
                        component: () => import( '@/views/journals/index.journals.view.vue'),
                        children:[
                          {
                            path: '',
                            name: 'journals-index',
                            component: () => import( '@/views/journals/list.journals.view.vue'),
                          },
                          {
                            path: 'constructor',
                            name: 'journals-constructor',
                            component: () => import( '@/views/journals/constructor.journals.view.vue'),
                          }
                        ]
                    },
                    { path: '404', component: () => import( '@/views/main/404.View.vue') },
                    { path: ':pathMatch(.*)*', component: () => import( '@/views/main/404.View.vue') },
                    ]
            },
            {
                      path: 'filemanager',
                      name: 'filemanager',
                      component: () => import( '@/views/main/filemanager.View.vue'),
                    },

        ]
    },


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    linkActiveClass: 'active',
    routes
})

 // const mainStore = useMainStore()
 //   const allowedPath = ref([] as any)
router.beforeEach((to, from, next) => {

    //await console.log(mainStore.getSytemMenu)

 /*  mainStore.getSytemMenu.forEach((sg : any)=>{
    if(sg.sys_menu_group){
       sg.sys_menu_group.forEach((s : any)=>{
           allowedPath.value.push(s.slug)
         /!* if( to.path.endsWith(s.slug)  ||  to.path.endsWith('/main') ||  to.path.endsWith('/404')){

            access.value = true
          }*!/

       })
    }

  })*/
   // console.log(allowedPath.value)
  const title = to.meta.title
      // const allowedChildren = ['/main/lessons','/main/timetable','/main/404','/main']
  if (title) {
    document.title = `${prefix} ${title}` as string
  }
/* if (allowedChildren && !allowedChildren.includes(to.path)) {
    next('/main/404')
  } else {
    next()
  }*/
  next()
})
//console.log(router)

export default router
