// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { ru , en} from 'vuetify/locale'
import { md3 } from 'vuetify/blueprints'
// Vuetify
import { createVuetify } from 'vuetify'
import { VDataTable } from 'vuetify/labs/VDataTable'

const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    'top_menu': '#FFFFFF',
    primary: '#3c70cc',
    'deep-purple-accent-4': '#3c70cc',
    'primary-darken-1': '#3700B3',
    secondary: '#0063ac',
    'secondary-darken-1': '#018786',
    error: '#8b0015',
    info: '#2196F3',
      no_records:'#767676',
    success: '#146a16',
    warning: '#FB8C00',
  }
}

export default createVuetify({
      blueprint: md3,
    theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    }
    },
    components: {
    VDataTable,
  },
    locale: {
    locale: 'ru',
    fallback: 'en',
    messages: { ru, en }
  }
    }

  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
)
