import {defineStore} from "pinia";
import {api} from '@/api';
import {getLocalToken, removeLocalToken, saveLocalToken} from '@/utils';
import {AxiosError} from 'axios';


import {AppNotification, IUserProfile, MainState} from "@/interfaces/user.model";
import router from "@/router";
import {ISystemMenu} from "@/interfaces/system.model";




  const defaultState: MainState = {
    //значения по умолчанию
    isLoggedIn: false,
    token: '',
    logInError: false,
    userProfile: {} as any,
    dashboardMiniDrawer: false,
    dashboardShowDrawer: true,
    notifications: [],
};
const context: MainState = defaultState;
// const defaultSystemMenu: ISystemMenu[] = [
//     {
//         title: null,
//         slug:null,
//         is_active:false,
//         is_visible:false,
//         description: null
//     }
// ]

export const useMainStore = defineStore({
    id: "mainStore",
    state: () => ({context: defaultState as MainState, systemMenu:[] as ISystemMenu[],searchData:[] as any} ),
    getters: {
        //getState(state){console.log("getState in Pinia", state.count) ;return state.count},
        getSystemMenu:(state)=>state.systemMenu ,
        getContext:(state)=>{return state.context},
        getLoginError:(state)=>state.context.logInError,
        hasAdminAccess(state) {
            return (
                state.context.userProfile &&
                state.context.userProfile.is_superuser &&
                state.context.userProfile.is_active
            );
        },
        loginError: (state) => state.context.logInError as boolean,
        getDashboardShowDrawer: (state) => state.context.dashboardShowDrawer,
        getDashboardMiniDrawer: (state) => state.context.dashboardMiniDrawer,
        getUserProfile: (state) => state.context.userProfile as IUserProfile,
        getToken: (state) => state.context.token,
        getIsLoggedIn: (state) => state.context.isLoggedIn as boolean,
        getFirstNotification: (state) => state.context.notifications.length > 0 && state.context.notifications[0],
        getSearchData:(state)=> state.searchData
    },
    actions: {
        //actions
        async actionLogIn(payload: { username: string; password: string }) {
            try {
                const response = await api.logInGetToken(payload.username, payload.password);
                const token = response.data.access_token;
                if (token) {
                    saveLocalToken(token);
                    this.setToken(this.context, token);
                    this.setLoggedIn(this.context, true);
                    this.setLogInError(this.context, false);
                    await this.actionGetUserProfile(this.context);
                    await this.actionRouteLoggedIn(this.context);
                    this.addNotification(this.context, {content: 'Logged in', color: 'success'});
                } else {
                    await this.actionLogOut(this.context);
                }
            } catch (err) {
                this.setLogInError(this.context, true);
                await this.actionLogOut(this.context);
            }
            if (process.env.VUE_APP_ENV === 'development') {
                console.log("CONTEXT STATE:", context)
            }
        }, /**/
        async actionGetUserProfile(context: MainState) {
            try {
                const response = await api.getMe(context.token);
                if (response.data) {
                    this.setUserProfile(context, response.data);
                }
            } catch (error) {
                // @ts-ignore
                await this.actionCheckApiError(context, error);
            }
        },
        async actionSearchInBase(token:string, data = {}) {
            try {
                const response = await api.searchInBase(token, data);
                if (response.data) {
                    return this.searchData = response.data
                    //this.setUserProfile(context, response.data);
                }
            } catch (error) {
                // @ts-ignore
                await this.actionCheckApiError(context, error);
            }
        },
        /*async actionUpdateUserProfile(context: MainContext, payload) {
            try {
                const loadingNotification = { content: 'saving', showProgress: true };
                commitAddNotification(context, loadingNotification);
                const response = (await Promise.all([
                    api.updateMe(context.state.token, payload),
                    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
                ]))[0];
                commitSetUserProfile(context, response.data);
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
            } catch (error) {
                await dispatchCheckApiError(context, error);
            }
        },*/
        async actionCheckLoggedIn(context: MainState) {
            if (!context.isLoggedIn) {
                let token = context.token;
                if (!token) {
                    const localToken = getLocalToken();
                    if (localToken) {
                        this.setToken(context, localToken);
                        token = localToken;
                    }
                }
                if (token) {
                    try {
                        const response = await api.getMe(token);
                        this.setLoggedIn(context, true);
                        this.setUserProfile(context, response.data);
                        // this.systemMenu = response.data.menu as ISystemMenu[];
                    } catch (error) {
                        await this.actionRemoveLogIn(context);
                    }
                } else {
                    await this.actionRemoveLogIn(context);
                }
            }
        },
        async actionRemoveLogIn(context: MainState) {
            removeLocalToken();
            this.setToken(context, '');

            this.setLoggedIn(context, false);
        },
        async actionLogOut(context: MainState) {

            await this.actionRemoveLogIn(context);

            await this.actionRouteLogOut(context);
        },
        async actionUserLogOut() {
            this.context.userProfile = {menu:[]}

            await this.actionLogOut(this.context);
            this.addNotification(this.context, {content: 'Logged out', color: 'success'});
        },
        async actionGetSystemMenu(){
            try {
                const response = await api.getSystemMenuInterface(context.token);
                if (response.data) {
                    this.systemMenu = response.data as ISystemMenu[];
                }
            } catch (error) {
                // @ts-ignore
                await this.actionCheckApiError(context, error);
            }
        },
        actionRouteLogOut(context: MainState) {
            if (router.currentRoute.value.path !== '/login') {
                 console.log('logout')
                 router.push('/login');
            }
        },
        async actionCheckApiError(context: MainState, payload: AxiosError) {

            if (payload.response!.status === 401) {
                await this.actionLogOut(context);
            }
        },
        actionRouteLoggedIn(context: MainState) {
            if (router.currentRoute.value.path === '/login' || router.currentRoute.value.path === '/') {
                router.push('/main');
            }
        },
        async actionRemoveNotification(context: MainState, payload: { notification: AppNotification, timeout: number }) {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    this.removeNotification(context, payload.notification);
                    resolve(true);
                }, payload.timeout);
            });
        },


        /*async passwordRecovery(context: MainContext, payload: { username: string }) {
            const loadingNotification = { content: 'Sending password recovery email', showProgress: true };
            try {
                commitAddNotification(context, loadingNotification);
                const response = (await Promise.all([
                    api.passwordRecovery(payload.username),
                    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
                ]))[0];
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' });
                await dispatchLogOut(context);
            } catch (error) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { color: 'error', content: 'Incorrect username' });
            }
        },
        async resetPassword(context: MainContext, payload: { password: string, token: string }) {
            const loadingNotification = { content: 'Resetting password', showProgress: true };
            try {
                commitAddNotification(context, loadingNotification);
                const response = (await Promise.all([
                    api.resetPassword(payload.password, payload.token),
                    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500)),
                ]))[0];
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
                await dispatchLogOut(context);
            } catch (error) {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
            }
        },*/

        //mutations

        // setSystemMenu(state:ISystemMenu, payload: ISystemMenu){
        //
        // },
        setToken(state:MainState, payload: string) {
            state.token = payload;
        },
        setLoggedIn(state: MainState, payload: boolean) {
            state.isLoggedIn = payload;
        },
        setLogInError(state: MainState, payload: boolean) {

            state.logInError = payload;

        },
        setUserProfile(state: MainState, payload: IUserProfile) {
            state.userProfile = payload;
        },
        setDashboardMiniDrawer(state: MainState, payload: boolean) {
            state.dashboardMiniDrawer = payload;
        },
        setDashboardShowDrawer(state: MainState, payload: boolean) {
            state.dashboardShowDrawer = payload;
        },
        addNotification(state: MainState, payload: AppNotification) {
            state.notifications.push(payload);
        },
        removeNotification(state: MainState, payload: AppNotification) {
            state.notifications = state.notifications.filter((notification) => notification !== payload);
        },
    }
});
