export const reviewsRouter = {
                        path: 'reviews',
                        name: 'main-reviews',
                        component: () => import( '@/views/reviews/index.reviews.view.vue'),
                        meta: {
                            title:'Manage Reviewss'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-reviews-list',
                                component: () => import( '@/views/reviews/list.reviews.view.vue')
                            },{
                                path: 'create',
                                name: 'main-reviews-create',
                                component: () => import( '@/views/reviews/create.reviews.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-reviews-edit',
                                component: () => import( '@/views/reviews/edit.reviews.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-reviews-preview',
                                component: () => import( '@/views/reviews/preview.reviews.view.vue')
                            },
                        ]
                    }