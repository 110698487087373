export const inventoryRouter = {
                        path: 'inventory',
                        name: 'main-inventory',
                        component: () => import( '@/views/inventory/index.inventory.view.vue'),
                        meta: {
                            title:'Manage Inventorys'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-inventory-list',
                                component: () => import( '@/views/inventory/list.inventory.view.vue')
                            },{
                                path: 'create',
                                name: 'main-inventory-create',
                                component: () => import( '@/views/inventory/create.inventory.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-inventory-edit',
                                component: () => import( '@/views/inventory/edit.inventory.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-inventory-preview',
                                component: () => import( '@/views/inventory/preview.inventory.view.vue')
                            },
                        ]
                    }