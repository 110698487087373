export const blogsRouter = {
                        path: 'blogs',
                        name: 'main-blogs',
                        component: () => import( '@/views/blogs/index.blogs.view.vue'),
                        meta: {
                            title:'Manage Blogss'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-blogs-list',
                                component: () => import( '@/views/blogs/list.blogs.view.vue')
                            },{
                                path: 'create',
                                name: 'main-blogs-create',
                                component: () => import( '@/views/blogs/create.blogs.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-blogs-edit',
                                component: () => import( '@/views/blogs/edit.blogs.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-blogs-preview',
                                component: () => import( '@/views/blogs/preview.blogs.view.vue')
                            },
                        ]
                    }