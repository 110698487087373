export const userPositionRouter = {
                        path: 'user-position',
                        name: 'main-user-position',
                        component: () => import( '@/views/user-position/index.user-position.view.vue'),
                        meta: {
                            title:'Manage UserPositions'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-user-position-list',
                                component: () => import( '@/views/user-position/list.user-position.view.vue')
                            },{
                                path: 'create',
                                name: 'main-user-position-create',
                                component: () => import( '@/views/user-position/create.user-position.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-user-position-edit',
                                component: () => import( '@/views/user-position/edit.user-position.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-user-position-preview',
                                component: () => import( '@/views/user-position/preview.user-position.view.vue')
                            },
                        ]
                    }