export const eventsRouter = {
                        path: 'events',
                        name: 'main-events',
                        component: () => import( '@/views/events/index.events.view.vue'),
                        meta: {
                            title:'Manage Eventss'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-events-list',
                                component: () => import( '@/views/events/list.events.view.vue')
                            },{
                                path: 'create',
                                name: 'main-events-create',
                                component: () => import( '@/views/events/create.events.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-events-edit',
                                component: () => import( '@/views/events/edit.events.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-events-preview',
                                component: () => import( '@/views/events/preview.events.view.vue')
                            },
                        ]
                    }