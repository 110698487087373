export const systemMenuRouter = {
                        path: 'system-menu',
                        name: 'main-system-menu',
                        component: () => import( '@/views/system-menu/index.system-menu.view.vue'),
                        meta: {
                            title:'Manage SystemMenus'
                        },
                        children: [
                            {
                                path: '',
                                name: 'main-system-menu-list',
                                component: () => import( '@/views/system-menu/list.system-menu.view.vue')
                            },{
                                path: 'create',
                                name: 'main-system-menu-create',
                                component: () => import( '@/views/system-menu/create.system-menu.view.vue')
                            },{
                                path: 'edit/:id',
                                name: 'main-system-menu-edit',
                                component: () => import( '@/views/system-menu/edit.system-menu.view.vue')
                            },{
                                path: 'preview/:id',
                                name: 'main-system-menu-preview',
                                component: () => import( '@/views/system-menu/preview.system-menu.view.vue')
                            },
                        ]
                    }